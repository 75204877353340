<template>
	<div class="grid">
		<div class="col-12">
            <div class="grid">
                <div class="col-9">
                    <div style="margin-bottom:10px;">
                        <a :href="'/cadastrar-anuncio/' + this.$router.currentRoute.value.params.id_marketplace"><Button label="Adicionar Anúncio" class="p-button-primary"/></a> 
                        <Button label="Importar Anuncio" class="btn_cancel"  @click="modalImportarAnuncios()"/>
                    </div>
                </div>
                <div class="col-3" style="text-align: right;">
                    <Button label="Vincular Anúncios" class="p-button-primary" v-if="this.modo_vincular" @click="modalVincular" />
                    <Button label="Mais Opções" class="p-button-primary"  @click="toggle($event)"/>
                    <Menu ref="menu" :model="items" :popup="true" />
                </div>
            </div>
			<div class="card">                                
                <div class="grid">
                    <div class="col-9">
                        <h5 class="titulo_pedidos">Anuncios - {{this.$router.currentRoute.value.params.nome_marketplace}}</h5>
                    </div>
                    <div class="col-3" style="text-align:right">
                        <Dropdown v-model="quantidadePorPagina" :options="quantidades" placeholder="Linhas" @change="setQuantidadePorPagina(quantidadePorPagina)" />
                    </div>
                </div>
                <div class="grid">
                    <div class="col-2">
                        <label>SKU</label>
                        <InputText v-model="sku_busca" style="width:100%" />
                    </div>
                    <div class="col-3">
                        <label>Título</label>
                        <InputText v-model="nome_busca" style="width:100%" />
                    </div>
                    <div class="col-3">
                        <Button type="button" class="p-button-primary" style="margin-top:20px" @click="buscarAnuncios()">
                            <span class="pi pi-search" style="margin-right: 10px;"></span> Buscar</Button>
                    </div>
                </div>
                <Loading v-if="loading"/>
				<table class="p-datatable-table" role="rowgroup" v-if="!loading">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <tr role="row">

                            <th class="p-sortable-column" tabindex="0" role="cell" v-if="modo_em_massa == true"><input type="checkbox" ></th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;"></th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Nome</span>
                                </div>
                            </th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;">SKU</th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;">Preço</th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;">Preço com <br /> Desconto</th>
                            <th class="p-sortable-column" tabindex="0" role="cell" style="text-align: start;">Ações</th>

                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody" role="rowgroup">
                        <tr v-for="(anuncio, index) in anuncios" :key="index" class="" role="row">
         
                            <td style="text-align: center;" v-if="modo_em_massa == true">
                                <input type="checkbox" @change="selecionarAnuncio($event, anuncio.id, anuncio.nome, anuncio.sku)">
                            </td>
                            <td><img :src="anuncio.imagem" class="imagem_produto"/></td>
                            <td>{{ anuncio.nome }}</td>
                            <td>{{ anuncio.sku }}</td>
                            <td>{{ anuncio.preco_normal_formatado }}</td>
                            <td>{{ anuncio.preco_desconto_formatado }}</td>
                            <td>
                                <button class="p-button p-component p-button-icon-only p-button-text" @click="carregarAnuncio(anuncio.id)">
                                    <i class="pi pi-search p-button-icon"></i>
                                </button>
                                
                                <button class="p-button p-component p-button-icon-only p-button-text" @click="carregarAnuncio(anuncio.id)"><i class="pi pi-pencil"></i></button>

                                <button style="color: red;" class="p-button p-component p-button-icon-only p-button-text" @click="excluirAnuncio(anuncio.id)">
                                    <i class="pi pi-times p-button-icon"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                <div v-if="quantidadePaginas > 1" class="p-paginator p-component p-paginator-bottom" style="width:100%">
                    <div 
                    style="padding: 5px;cursor: pointer;" @click="primeiraPagina()"> Primeira </div><div style="padding: 5px;cursor: pointer;" @click="anteriorPagina()">Anterior</div>
                    <div style="padding: 5px;cursor: pointer;" v-for="pagina in quantidadePaginas" :key="pagina" @click="paginaAtual(pagina)">{{ pagina }}</div>
                    <div style="padding: 5px;cursor: pointer;" @click="proximaPagina()">Próxima</div>
                    <div style="padding: 5px;cursor: pointer;" @click="ultimaPagina()">Ultima</div>
                </div>
			</div>
		</div>
	</div>
    <Dialog header="Adicionar Produto" v-model:visible="mostrarAdicionar" >
        <div class="flex flex-column">
            <InputText placeholder="Nome" v-model="produtosAdicionar['nome']" />
            <Textarea placeholder="Descrição" :autoResize="true" rows="5" cols="30" v-model="produtosAdicionar['descricao']" />
            <div class="flex">
                <InputText placeholder="sku" v-model="produtosAdicionar['sku']" />
                <InputText placeholder="ean" v-model="produtosAdicionar['ean']" />
            </div>
            <div class="flex">
                <InputText placeholder="Preço Normal" v-model="produtosAdicionar['preco_normal']" />
                <InputText placeholder="Preço com Desconto" v-model="produtosAdicionar['preco_desconto']" />
            </div>
            <div class="flex">
                <InputText placeholder="Altura" v-model="produtosAdicionar['altura']" />
                <InputText placeholder="Largura" v-model="produtosAdicionar['largura']" />
            </div>
            <div class="flex">
                <InputText placeholder="Profundidade" v-model="produtosAdicionar['profundidade']" />
                <InputText placeholder="Peso" v-model="produtosAdicionar['peso']" />
            </div>
            <div class="flex">
                <InputText placeholder="Estoque" v-model="produtosAdicionar['estoque']" />
                <InputText placeholder="Vídeo" v-model="produtosAdicionar['video']" />
            </div>
            <div class="flex">
                <Button label="Adicionar" class="p-button-success" @click="adicionarAnuncio()"/> 
                <Button label="Cancelar" class="p-button-danger" @click="limparFormProduto()"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Importar Anúncios" v-model:visible="mostrarImportar"  :style="{width: '50vw'}">
        <div class="flex flex-column">
            <div style="height:20vw">
                <label>Qual tipo de Importação deseja fazer?</label>
                <div class="flex flex-column">
                    <Dropdown v-model="tipo_importacao" :options="tiposImportacao" optionLabel="nome" optionValue="valor" class="largura_maxima" />
                </div>

                <div class="flex flex-column largura_maxima" v-if="tipo_importacao=='ESPECIFICO'">
                    <label>Identificador do anúncio</label>
                    <InputText style=" display: inline; " class="largura_maxima" v-model="this.sku_importacao"/>
                </div>
            </div>
            <div class="centro">
                <Button label="Continuar" class="p-button-success btn_success" @click="importarAnuncios()" style="margin-right:10px"/> 
                <Button label="Cancelar AA" class="p-button-danger btn_cancel" @click="fecharImportar()"/> 
            </div>
        </div>
    </Dialog>

        <Dialog header="Vincular Anuncios" v-model:visible="mostrarModalVincularAnuncio"  :style="{ width: '50vw' }">
            <div class="flex flex-column">
                <div style="height:20vw">
                    
                    <div style="margin-bottom: 15px;" v-if="!modo_continuar">
                        <label style="font-weight: bold;">O que deseja fazer?</label>
                        <Dropdown v-model="tipoVinculacao" :options="tiposVinculacao" optionLabel="nome" optionValue="valor" class="largura_maxima" />
                    </div>
                    <div v-if="tipoVinculacao == 'PRODUTO_EXISTENTE'">
                        <label v-if="!modo_continuar">SKU</label>
                        <div class="flex flex-column" v-if="!modo_continuar">
                            <InputText v-model="sku_vincular"/>
                        </div>
                        <div v-if="modo_continuar" >
                            <label style="font-weight: bold;">SKU do Produto</label>
                            <p>{{ sku_vincular }}</p>
                            <table>
                                <thead>
                                    <th style="text-align: start;">Anuncio</th>
                                    <th style="text-align: start;">SKU</th>
                                </thead>
                                <tbody>
                                    <tr v-for="anc in this.anunciosSelecionados" :key="anc">
                                        <td>{{ anc.nome }}</td>
                                        <td>{{ anc.sku }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tipoVinculacao == 'NOVO_PRODUTO' && modo_continuar">
                        <label>Criar produto a partir de:</label>
                        <Dropdown v-model="anuncio_pai" :options="anunciosSelecionados" optionLabel="nome_select" optionValue="id" class="largura_maxima" />
                        
                    </div>
                    

                </div>
                <div class="centro">
                    <Button label="Vincular" class="p-button-success btn_success" @click="vincularProdutoExistente()" v-if="tipoVinculacao == 'PRODUTO_EXISTENTE' && modo_continuar"/>
                    <Button label="Cadastrar e Vincular" class="p-button-success btn_success" @click="cadastrarProdutoVincularAnuncios()" v-if="tipoVinculacao == 'NOVO_PRODUTO' && modo_continuar"/>

                    <Button label="Continuar" class="p-button-success btn_success" @click="executarVinculacao()" style="margin-right:10px" v-if="!modo_continuar"/> 
                    <Button label="Cancelar" class="p-button-danger btn_cancel" @click="fecharVincular()"/> 
                </div>
            </div>
        </Dialog>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import RadioButton from 'primevue/radiobutton';
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast";


	export default {
        setup(){
            const router = useRoute()
            const toast = useToast();
        return{
            router,
            toast
        }
    },
    
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
            msg_erro: 'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            modo_em_massa: false,
            modo_vincular: false,
            modo_continuar: false,
            nome_produto:"",
            tipoVinculacao:"",
            mostrarModalVincularAnuncio: false,
            empresa_id:0,
            api: "",
            loading: true,
            anuncios: null,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            quantidades: ["10", "20", "50"],
            anunciosSelecionados: [],
            anuncio_pai:{},
            
            items: [
                {
                    label: 'Vincular Anúncios',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.vincularAnuncios()
                    }
                }/*,
				{
					label: 'Vue Website',
					icon: 'pi pi-external-link',
					command: () => {
						window.location.href = 'https://vuejs.org/'
					}
				},
				{
					label: 'Upload',
					icon: 'pi pi-upload',
                    to: '/fileupload'
				}*/
            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { ean: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
            ],
            tiposImportacao: [
                { nome: "Todos os anúncios", valor:"TODOS" },
                { nome: "Apenas anúncios novos", valor:"NOVOS" },
                { nome: "Um anúncio específico", valor:"ESPECIFICO" },
            ],
            tiposVinculacao: [
                { nome: "Vincular a Produto Existente", valor: "PRODUTO_EXISTENTE" },
                { nome: "Vincular a Novo Produto", valor: "NOVO_PRODUTO" },
            ],
            sku_busca:"",
            mostrarAdicionar: false,
            mostrarImportar:false,
            tipo_importacao:"",
            sku_importacao:"",
            nome_marketplace:"",
            RadioButton
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.nome_marketplace = this.$router.currentRoute.value.params.nome_marketplace
        this.api = new Api();
        this.integracao_id = this.$router.currentRoute.value.params.id_marketplace;
        this.api.qtdAnunciosPorIntegracao(this.token,this.empresa_id,this.integracao_id).then(data => { this.totalLinhas = data.data.quantidade 
        }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
        }); 
        
    },
    mounted() {
        this.listarAnuncios();
    },
    methods: {
        toggle(event) {
            console.log(this.$refs.menu);
            //this.status_selecionado = status

            this.$refs.menu.toggle(event);

            //  this.pedido_selecionado = pedido_id

            //  :model="items"
            //    this.$refs.menu.model(this.items);
        },
        listarAnuncios(pagina = this.pagina, quantidadePorPagina = this.quantidadePorPagina, ordenar = this.ordenar, tipoOrdenar = this.tipoOrdenar) {
            this.loading = true;
            var integracao_id = this.$router.currentRoute.value.params.id_marketplace
            this.api.listarAnuncios(this.token, this.empresa_id, integracao_id, pagina, quantidadePorPagina, ordenar, tipoOrdenar).then(data => {
                this.anuncios = data.data.resposta
                this.quantidadePaginas = parseInt(this.totalLinhas) / parseInt(this.quantidadePorPagina)
                this.quantidadePaginas = Math.ceil(this.quantidadePaginas)
                this.loading = false
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        buscarAnuncios() {
            if (this.sku_busca.length > 0) {
                this.api.buscarAnuncioPorSKU({ token: this.token, integracao_id: this.integracao_id, empresa_id: this.empresa_id, sku_busca: this.sku_busca }).then(data => {
                //    console.log(data.data.erro);
                    if (data.data.verifica_erro == false) {
                        this.anuncios = data.data.resposta
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            } else {
                this.api.buscarAnuncioPorNome({ token: this.token, nome_busca: this.nome_busca, integracao_id: this.integracao_idm, empresa_id: this.empresa_id }).then(data => {
                    if (data.data.verifica_erro == false) {
                        this.anuncios = data.data.resposta
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }
            console.log(this.sku_busca.length)
        },
        vincularAnuncios() {
            this.modo_em_massa = true;
            this.modo_vincular = true;
          //  console.log("Vincular")
        },
        modalVincular() {
            this.mostrarModalVincularAnuncio = true;
        },
        executarVinculacao() {
            this.modo_continuar = true;
            
            if (this.tipoVinculacao == 'PRODUTO_EXISTENTE') {
                this.vincularEmProdutoExistente()
            } else if(this.tipoVinculacao == 'NOVO_PRODUTO'){
                this.vincularEmNovoProduto()
            }
        },
        vincularEmProdutoExistente() {
            this.api.selecionaProdutoPorSKU(this.token, this.sku_vincular, this.empresa_id).then(data => {
                if (data.data.verifica_erro == false) {
                    if (data.data.resposta != null) {
                    //    console.log(data.data.resposta.nome);
                        this.nome_produto = data.data.resposta.nome;
                        this.produto_id = data.data.resposta.id
                    } else {
                        alert("Produdo de SKU "+ this.sku_vincular +" não encontrado!")
                    }
                    
                } else {
                    alert(this.msg_erro);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        vincularEmNovoProduto() {
            console.log("Teste");
        },
        vincularProdutoExistente() {
            this.api.vincularVariosAnunciosProduto({ token: this.token, anuncios: this.anunciosSelecionados, produto_id: this.produto_id, empresa_id:this.empresa_id }).then(data => {
                if (data.data.verifica_erro == false) {
                    this.anunciosSelecionados.splice(0, this.anunciosSelecionados.length);
                    this.mostrarModalVincularAnuncio = false;
                    this.toast.add({ severity: 'success', summary: 'Integração Realizada com Sucesso!', detail: '', life: 3000 });

                } else {
                    alert(this.msg_erro);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        cadastrarProdutoVincularAnuncios() {
            this.api.cadastrarProdutoVincularAnuncios({ token: this.token, anuncios: this.anunciosSelecionados, anuncio_pai: this.anuncio_pai, empresa_id: this.empresa_id }).then(data => {
                if (data.data.verifica_erro == false) {
                    this.anunciosSelecionados.splice(0, this.anunciosSelecionados.length);
                    this.mostrarModalVincularAnuncio = false;
                    this.toast.add({ severity: 'success', summary: 'Integração Realizada com Sucesso!', detail: '', life: 3000 });

                } else {
                    alert(this.msg_erro);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        show() {
            this.toast.add({ severity: 'success', summary: 'Integração Realizada com Sucesso!', detail: '', life: 3000 });
        },

        fecharVincular() {
            this.mostrarModalVincularAnuncio = false
        },

        selecionarAnuncio(event, anuncio_id, nome_anuncio,sku) {
            //Verifica se está checado
            //Se for checado
            if (event.target.checked) {
                this.anunciosSelecionados.push({id:anuncio_id, nome: nome_anuncio, sku: sku, nome_select:nome_anuncio+" - "+sku})
            } else {
                for (let i = 0; i < this.anunciosSelecionados.length; i++) {
                    console.log(this.anunciosSelecionados[i].id)
                    if (anuncio_id == this.anunciosSelecionados[i].id) {
                        this.anunciosSelecionados.splice(i, 1);
                    }
                }
            }
            console.log(this.anunciosSelecionados)
        },
        carregarAnuncio(anuncio_id){
            this.$router.push({ name: 'anuncio', params: { id: anuncio_id,integracao: this.$router.currentRoute.value.params.id_marketplace } })
        },

        excluirAnuncio(anuncio_id) {
            if (confirm("Deseja apagar este Anúncio?")) {
                this.api.excluirAnuncio(this.token, anuncio_id, this.empresa_id).then(data => {
                    if (data.data.verifica_erro == false) {
                        this.listarAnuncios();
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }

        },
        setQuantidadePorPagina(quantidade) {
            this.quantidadePorPagina = quantidade;
            this.listarAnuncios();
        },
        paginaAtual(pagina) {
            this.pagina = pagina;
            // this.primeiroItemDaPagina = pagina * this.quantidadePorPagina + 1 - this.quantidadePorPagina;
            this.listarAnuncios();
        },
        proximaPagina() {
            if (this.quantidadePaginas > this.pagina)
                this.paginaAtual(this.pagina + 1);
                this.listarAnuncios();
        },
        anteriorPagina() {
            if (this.pagina > 1) {
                this.paginaAtual(this.pagina - 1);
                this.listarAnuncios();
            }
        },
        primeiraPagina() {
            this.paginaAtual(1);
            this.listarAnuncios();
        },
        ultimaPagina() {
            this.paginaAtual(this.quantidadePaginas);
            this.listarAnuncios();
        },
        importarAnuncios() {
            this.api.cargaAnuncios(
                this.token, 
                this.empresa_id,
                this.integracao_id,
                this.nome_marketplace, 
                this.tipo_importacao,
                this.sku_importacao).then(data => {
                if(data.verifica_erro != false){
                    this.fecharImportar();
                    this.listarAnuncios();
                }else{
                    alert(this.msg_erro);
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        //    this.limparFormAnuncio();
        },

        modalImportarAnuncios(){
            this.mostrarImportar = true;

        },
        fecharImportar(){
            this.mostrarImportar = false;
        },
        
        limparFormAnuncio() {
            for (let field in this.produtosAdicionar) {
                this.anunciosAdicionar[field] = "";
            }
            this.mostrarAdicionar = false;
        },

        relacionarAnuncios(){

        }
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
.titulo_anuncio{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
table{
    width:100%;
}
.botao_salvar{
    margin-left:10px;
}
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
}
.btn_success{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.btn_success:hover{
    background-color:#4e87ee !important; 
    border:none; 
    margin-right:10px;
}

.btn_cancel{
    background-color:#9d9d9d; 
    border:none
}

.btn_cancel:hover{
    background-color:#9d9d9d !important; 
    border:none
}
.largura_maxima{
    width: 100%;
    margin-top: 10px;
}
.centro{
    text-align:center;
}


.p-button-primary{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.p-button-primary:hover{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.imagem_produto{
    width: 50px;
}
</style>
